// src/components/NotFound.js
import React from 'react';
import "./NotFound.css";
import Navbar from './Navbar';
import Footer from './Footer';

const NotFound = () => {
  return (
    <div className="home-container">         
        <Navbar /> 
    <div className="not-found-container">
      <h1>404</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
    </div>
    <Footer />
    </div>
  );
};

export default NotFound;
